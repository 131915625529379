*{
    margin: 0;
}
.sidebar{
    position: fixed;
    left: 0;
    bottom: 0;
    top: 58px;
    width: 255px;
    background-color: white;
    padding: 5px 12px;
    border-right: 1px solid #77777729;
    box-shadow: 0 0 6px #77777729;
}

.sidebar-icon{
    display: flex;
    padding: 14px 21px;

    i{
        width: 41px;
        height: 39px;
    }
}

.main-page{
    display: flex;
    margin: 0;
    max-width: 100% !important;
}

.main-content{
    position: fixed;
    width: 82%;
    padding: 20px 0 0 20px;
    margin-left: 235px;
}

.sidebar-content{
    display: flex;
    flex-direction: row;
    padding-left: 8px;
    justify-content: left;
    text-decoration: none;
    color: black;

    .icon{
        display: flex;
        flex-direction: row;
        align-items: center;

        .sidebar-image{
            height: 24px;
            width: 24px;
            margin: 12px 10px;
        }
    }

    a{
        width: 215px;
        text-decoration: none;
        color: black;
    }

    .sidebar-link{
        padding: 10px 0 10px 5px;
        flex-direction: column;
        cursor: pointer;
        font-weight: 600;
        font-size: 14px;
    }

    .active{
        width: 100%;
        background: #1063A2 !important;
        color: white;
        border-radius: 6px;

        .sidebar-image{
            filter: brightness(0) invert(1);
        }
    }
}