.addJobOuterWrapper {
  .newJobDiv {
    height: 1rem;

    .newJob {
      padding: 2rem 0 1rem 2rem;
      font-size: 14px;
      color: #144870;
      font-weight: bold;
    }
  }

  .addJobBtnWrapper {
    float: right;
    margin: 1rem 2rem 1rem 0;
    font-size: 14px;

    .inActiveBtn {
      width: 6rem;
      height: 3rem;
      background: #fff;
      border: 1px solid #0e75c4;
      color: #0e75c4;
      margin-right: 1rem;
      display: inline;
      font-weight: bold;
    }

    .activeBtn {
      width: 6rem;
      height: 3rem;
      background: #0e75c4;
      border: 1px solid #0e75c4;
      color: #fff;
      display: inline;
      font-weight: bold;
    }
  }

  .addJobTextWrapper {
    float: left;
    margin-top: 5rem;
    font-size: 14px;

    td {
      padding: 1rem 1rem 1rem 2rem;
      vertical-align: baseline;

      .edit-duplicate {
        color: red;
        font-size: 12px;
      }
    }

    .addJobInputBox {
      width: 20rem;
      height: 2rem;
      font-size: 14px;
    }
  }
}
