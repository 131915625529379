.lookup-title{
    border-bottom: 1px solid #dee2e6;
    
    .lookup-header{
        font-weight: bold;
        font-size: 16px;
        padding: 10px 20px;
    }
}

.lookup-content{

    .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: inset 0 0 0 0;
    }

    .p-tabview .p-tabview-panels{
        padding: 0;
    }

    .p-tabview-nav-content{
        height: 44px;
        border: 1px solid #dee2e6;
    }

    .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link{
        color: #213848;
        font-size: 16px;
        font-weight: bold;
    }

    .p-tabview .p-tabview-nav li .p-tabview-nav-link{
        color: #213848;
        font-size: 16px;
        font-weight: 400;
    }

    .p-tabview .p-tabview-nav .p-tabview-ink-bar{
        background-color: #0E75C4;
    }

    .p-component {
        font-family: "Open Sans", sans-serif !important;
      }

}

  