// Colors
$textColor: #000;
$headerBackground:   #1063A2;
$textColorGray: #6c757d;

// Breakpoints
$mobileMaxWidth: 768px;

//fonts
$fontWeight: 400;
$fontWeightBold: 700;
