.switch{
    margin-right: 10px;
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
    border-radius: 50%;

    input{
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider{
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        background-color: #D2D2D4;
        border: none;
        border-radius: 10px;
        transition: 0.4s;
    }

    .slider::before{
        position: absolute;
        content: "";
        height: 11px;
        width: 11px;
        left: 3px;
        bottom: 2px;
        background-color: white;
        border-radius: 50%;
        transition: 0.4s;
    }

    input:checked + .slider{
        background-color: #109DE7;
    }

    input:checked + .slider::before{
        transform: translateX(11px);
    }
}