.EE{


.new-equipment-button{
  position: fixed;
    right: 10px;
    bottom: 12px;
}

.ErrorIn-row{
  
}

.save-edit{
  color: black;
}
.disable-save-edit{
  color: gray;
}
.Main-Content{
  display: flex;
  margin-top: 10px;
  max-height: 277px;
  overflow-y: auto;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
  .content-Row {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-left: 30px;
}
}
.error-message{
  color: red;
  font-size: medium;
}

.bottom-left-button-container {
  position: absolute;
  bottom: 10px;
  left: 10px;
}



.Add-Input{
  margin-left: 40px;
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 13px;
}

.new-equipment-button:hover {
  background-color: #0056b3;
}

.footerEE{
  display: flex;
  align-items: center;
  border-top: 2px solid #dee2e6;
  width: 100%;
  position: fixed;
  height: 45px;
  bottom: 0;
  z-index: 36;
  background-color: white;
  .new-equipment-button {
    width: 125px;
    left: 75.5rem;
    position: sticky;
    margin-top: 5px;
    border: none;
    border-radius: 3px;
    background-color: #0E75C4;
    color: white;
    font-size: 14px;
    padding: 5px;
  }
}

input{
  width: 300px;
  border-radius: 5px;
  border: 1px solid rgba(16, 99, 162, 0.5);
  background-color: rgba(189, 228, 248, 0.3);
  padding-left: 10px;
}
.input-edit{
  background-color: white;
}

.pi{
  cursor: pointer;
  height: 19px;
}}