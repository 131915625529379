@import "../../sassStyles/variables";

.user_profile {
  margin-right: 25px;
  position: relative;
  .p-overlay-badge {
    left: 2.3rem;
    top: 2.4rem;
    position: absolute;
  }
  .p-badge.p-badge-success {
    border: 1px solid #fff;
    padding: 0.3rem;
    background-color: #009135;
  }
  .user_icon {
    width: 35px;
    height: 35px;
    position: relative;
    border: 1px solid white;
    border-radius: 50%;
    margin: 8px;
  }
}

.p-overlaypanel {
  // border-color: 10px solid #144870 !important;
  margin-top: 30px !important;
  .p-overlaypanel-content {
    padding: 0px !important;
  }
}

.popover-parent {
  .user-avatar-wrapper {
    .user-avatar {
      flex-direction: column;
      display: flex;
      margin: 1.4rem auto;
      border-radius: 50%;
      width: 65px;
      height: 65px;
      border: 2px solid #ffffff;
    }
  }
  .popover-name-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    word-break: break-all;
    margin-top: -1rem;
    text-align: center;

    .username_span {
      font-size: 18px;
      font-weight: 500;
      color: #03243d;
    }

    .email_span {
      color: #445968;
      font-weight: 400;
      padding-bottom: 12px;
      border-bottom: 1px solid #ebebeb;
      font-size: 13px;
    }
  }
}

.header_overlay {
  height: 55px;
  width: 100%;
  top: 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background-color: #92d2f4;
  position: absolute;
  z-index: -1;
}

.appversion {
  color: #445968;
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  margin: 0 10px 0 10px;
  border-bottom: 1px solid #ebebeb;
}

.line-style {
  cursor: pointer;
  font-size: 15px;
  color: #2f4656;
  padding: 8px;
  margin-left: 10px;
  margin-top: 10px;
}

@media only screen and (max-width: 992px) {
  .user_icon {
    width: 25px;
    height: 25px;
    margin: 5px;
  }
}
