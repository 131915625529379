.p-dialog .p-dialog-header-icons {
  display: none;
}

.p-dialog {
  width: 607px;
  height: fit-content;
}

.p-component {
  font-family: "Open Sans", sans-serif !important;
}

.p-dialog .p-dialog-header {
  padding: .5rem;
}

.p-dialog .p-dialog-content {
  display: flex;
  justify-content: center;
  color: #03243d;
  font-weight: 600;
  padding-bottom: 0rem;
}

.success-popup {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 1.5rem;
  
  .successLogo {
    // position: absolute;
    left: 35px;
    top: 35px;
    right: 35px;
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
  }

  .successFormWrapperInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    p {
      margin: 0;
    }

    .successTitleHead {
      text-align: center;
      font-weight: bold;
      font-size: 22px;
      color: #03243d;
    }

    .successTitle {
      font-size: 14px;
      color: #03243d;
      padding-top: 5px;
      text-align: center
    }
  }

  .popupButtonWrapper {
    position: relative;
    margin-top: 25px;
    right: -12rem;
    margin-left: 2rem;

    .close-button {
      width: 121px;
      height: 32px;
      border-radius: 3px;
    }

    .close-button,
    .close-button:hover {
      background-color: #0e75c4;
      color: white;
      margin-left: 1rem;
    }
  }
}

.p-dialog .p-dialog-footer button {
  width: 121px;
}
