// AccordionComponent.scss

// Reset default margins and paddings
// Your existing styles
// ...
.header {
  .p-accordion-header-text{
  
    font: normal normal bold 14px/19px 'Open Sans', sans-serif;
  }
  .table-container {
    border-collapse: collapse;
    width: 100%;
      .table-header{
        background-color: #E9F6FC9A;
      }
    th,
    td {
      padding-left: 10px;
      text-align: left;
    }

    th {
      border-bottom: 2px solid black;
    }

    td {
      border-top: 1px solid #ddd;
      padding-top: 5px;
    }

    td:first-child {
      border-left: none;
    }

    td:last-child {
      border-right: none;
    }
    .edit-iconEE {
      margin: 0px 10px;
      height: 15px;
    }
    .delete-iconEE{
      // width: 25px; 
      margin: 0px 10px;
      height: 15px;
      /* padding-bottom: 0px; */
  
    }

    .saveApp {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
.p-accordion .p-accordion-content {
  padding: 0;
}

.accordian-container {
  padding: 0 3px 0 16px;
}

.titleMP {
  font-weight: bold;
  height: 20px;
  padding: 5px 52px;
}

.scrolable-containerMP {
  /* Other styles */

  .scrollable-content {
    max-height: 400px;
    overflow: auto;
  }
}

.input-styling-appNO {
  width: 100px;
  border: 1px solid black;
  // font-size: inherit;
  background-color: transparent;
  box-shadow: none;
}

.input-styling-appDesc {
  border: 1px solid black;
  // font-size: inherit;
  background-color: transparent;
  box-shadow: none;
}

.header.p-accordion-header .p-accordion-header-link,
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link,
.header.p-accordion-header .p-accordion-header-link:hover,
.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight
  .p-accordion-header-link:hover {
  background: #128dddc7; // Your desired background color
  color: white;
  height: 20px;
  /* Add any other styles you need */
}
/* Add any other styles you need */

// .appNO{
//   padding-left: 10px;
//   input{
//     width: 100px;
//   }
// }

// .appDesc{
//   padding-left: 10px;
//   input{
//     width: 400px;  }

// }

.App-Number {
  width: 25%;
  input {
    width: 100px;
  }
  .error-message{
    color: red;
  }
}
.App-Desc {
  width: 75%;

  .error-message{
    color: red;
  }
  input{
    width: inherit;
  }
  img {
    padding-left: 10px;
    cursor: pointer;
  }
}
