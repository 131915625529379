/* In your CSS file */
.aoc-content-wrapper{
  overflow: auto;
  height: calc(93vh - 152px);
  padding-top: 1.25rem;

  .footer-menu {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(400px, 1fr)
    ); /* Adjust column width as needed */
    grid-gap: 20px; /* Adjust the gap between items */
    max-width: 1050px; /* Adjust the maximum width of the menu */
    margin: 0 auto;

    .no-result{
      font-size: 16px;
      font-weight: 600;
      color: #2F4656;
    }
    
  }
  
  .title-row {
    margin: 10px 0px;
  }
  
  ul {
    list-style-type: none;
    // padding: 0;
  }
  
  .letter-head {
    width: 23px;
    height: 23px;
    background-color: #0059b3;
    color: white;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 14px; 
  }
  
  .aoc-edit-content{
    .aoc-input{
      width: 216px;
      height: 26px;
      border: 1px solid #9DACB6;
      border-radius: 4px;
      padding: 10px;
      font-size: 14px;
      color: #2F4656;
      margin-right: 10px;
    }

    .edit-enable, .close-edit, .edit-disable {
      cursor: pointer; 
      height: 14px;
      width: 14px;
      margin-right: 12px;
    }

    .edit-aoc-error, .edit-dupe-error{
      font-size: 12px;
      color: red;
    }
  }

  .aoc-content-clickables{

    .aoc-names{
      font-size: 14px;
      color: #2F4656;
    }

    .edit-icon {
      height: 12px;
      padding: 0px 20px 0px 20px;
      cursor: pointer;
    }
    
    .trash-icon {
      height: 12px;
      width: 12px;
      cursor: pointer;
    }
  }
}

.aoc-wrapper {

  .header-Aoc {
    margin: 10px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .search-bar {
      width: 370px;
      height: 32px;
      border: 1px solid #9DACB6;
      border-radius: 4px;
      padding: 7px 17px;
      color: #144870;
      font-size: 14px;
      font-weight: bold;
    }

    .add-button {
      width: 105px;
      height: 32px;
      background-color: #687782;
      color: white;
      font-size: 14px;
      border: none;
      border-radius: 3px;
      font-weight: bold;
    }

    .new-aoc-text {
      width: 307px;
      height: 32px;
      padding: 9px 10px;
      color: #14487098;
      font-size: 10px;
      font-weight: bold;
      border: 1px solid #9dacb6;
      border-radius: 4px;
    }

    .add-Aoc {
      display: flex;
      gap: 10px;
      align-items: center;

      .new-enable, .new-disable, .new-close{
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .new-aoc-error, .new-dupe-error{
        font-size: 12px;
        color: red;
        position: relative;
        right: 24rem;
        top: 30px;
      }
    }
  }
  .p-divider.p-divider-horizontal {
    margin: 0;
  }
}
