.access-denied {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 94vh;

  .access-lock{
    width: 86px;
    height: 86px;
    margin-bottom: 43px;
  }

  .access-heading{
    font-size: 40px;
    font-weight: 600;
    color: #03243D;
    margin: 0;
  }

  .acess-content{
    font-size: 16px;
    font-weight: 600;
    color: #03243D;
    margin-bottom: 56px;
  }

  .logout{
    background-color: #0E75C4;
    width: 109px;
    height: 44px;
    color: white;
    border: none;
    border-radius: 6px;
  }
}
