.configuration-title {
  border-bottom: 2px solid #dee2e6;

  .configuration-header {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
    align-items: center;
  }

  .config-cancel-button,
  .config-save-button,
  .save-disable,
  .cancel-disable {
    font-size: 14px;
    position: absolute;
    width: 122px;
    height: 32px;
    border-radius: 3px;
    font-weight: bold;
  }

  .config-cancel-button {
    right: 170px;
    border: 1px solid #0e75c4;
    background-color: #e9f6fc;
    color: #0e75c4;
  }

  .config-save-button {
    right: 35px;
    color: white;
    border: 1px solid #0e75c4;
    background-color: #0e75c4;
  }

  .cancel-disable {
    right: 170px;
    background-color: #03243d1f;
    border: 1px solid #1448709a;
    color: #144870a5;
  }

  .save-disable {
    right: 35px;
    color: white;
    border: none;
    background-color: #9dacb6;
  }
}

.config-content {
  margin-top: 40px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;

  *:focus {
    outline: none;
  }

  .job-retention {
    display: flex;

    p {
      margin-right: 25px;
    }
    .content-title {
      font-size: 14px;
      color: #03243d;
    }

    .job-years {
      display: flex;
      margin-right: 10px;

      input {
        position: relative;
        width: 45px;
        height: 24px;
        margin-right: 10px;
        overflow: hidden;
        font-size: 14px;
        color: #03243d;
        border: 1px solid #9dacb6;
      }

      p {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #606060;
      }

      .years-error {
        position: absolute;
        top: 180px;
        font-size: 12px;
        color: red;
      }
    }

    .job-info {
      display: flex;
      border: 1px solid #fbc768;
      border-radius: 15px;
      background-color: #fde7be;
      padding: 5px 10px;
      height: 30px;

      i {
        position: relative;
        margin-right: 10px;
        top: 2px;
      }

      .triangle-up {
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 14px solid #faa713;
        margin-right: 10px;
      }

      p {
        position: relative;
        bottom: 2px;
        font-weight: 600;
        font-size: 14px;
        color: #144870;
      }
    }
  }

  .heat-index {
    display: flex;
    margin-top: 35px;

    p {
      margin-right: 25px;
    }

    .content-title {
      font-size: 14px;
      color: #03243d;
    }

    .switch {
      top: 5px;
      margin-right: 25px;
    }

    .heat-condition {
      color: #606060;
      font-size: 12px;
      margin-top: 3px;
      margin-right: 52px;
    }

    .p-inputtext {
      border: 1px solid #9dacb6;
      color: #2f4656;
      font-size: 12px;
    }

    .message-error {
      position: absolute;
      top: 23.5rem;
      left: 22.5rem;
      font-size: 12px;
      color: red;
    }
  }
}

.p-inputtext {
  font-family: "Open Sans", sans-serif !important;
}
