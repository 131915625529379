@import "../../../sassStyles/mixins";

.addTaskOuterWrapper {
  .addTaskBtnWrapper {
    float: right;
    margin: 2rem 2rem 1rem 0;
    font-size: 14px;

    .inActiveBtn {
      width: 6rem;
      height: 3rem;
      background: #fff;
      border: 1px solid #0e75c4;
      color: #0e75c4;
      margin-right: 1rem;
      display: inline;
      font-weight: bold;
    }

    .activeBtn {
      width: 6rem;
      height: 3rem;
      background: #0e75c4;
      border: 1px solid #0e75c4;
      color: #fff;
      display: inline;
      font-weight: bold;
    }
  }

  .addTaskTextWrapper {
    float: left;
    margin-top: 5rem;
    font-size: 14px;

    td {
      padding: 1rem 1rem 1rem 2rem;
      vertical-align: baseline;
      .edit-duplicate {
        color: red;
        font-size: 12px;
      }
    }

    .addTaskInputBox {
      width: 20rem;
      height: 2rem;
      font-size: 14px;
    }
  }

  .addTaskBtnOuterWrapper {
    height: 10rem;
  }

  .tableWrapper {
    margin-left: 10px;
    margin: 30px 10px;
    overflow: auto;
    overflow-x: hidden;
    height: fit-content;
    max-height: 350px;
    @include scrollbar-style;

    table {
      border-spacing: 0;
      border-collapse: separate;
      border-radius: 10px;
      border: 1px solid #0e75c4;
      overflow: hidden;
      width: 100%;
      th {
        background-color: #128dddc7;
        color: #ffffff;
        font-size: 14px;
        padding: 10px;
      }
      td {
        padding: 10px;
        background-color: #e9f6fc9a;
        color: #445968;
        font-size: 12px;
        ol {
          margin-left: -15px;
          li {
            list-style-type: disc;
          }
        }

        .imagePath-edit {
          display: flex;
          .swiIconImage {
            height: 125px;
            margin-top: 10px;
            object-fit: contain;
          }
          .edit-icon,
          .trash-icon {
            margin-top: 45px;
          }
        }

        .imagePath-edit,
        .highEnergyTask-edit,
        .highEnergyTask-delete,
        .hazard-edit,
        .hazard-delete,
        .directControl-edit,
        .directControl-delete,
        .itemSafeguard-edit,
        .itemSafeguard-delete {
          .edit-icon,
          .trash-icon {
            height: 12px;
            padding: 0 10px;
          }
        }

        .highEnergyTaskBtn,
        .hazardBtn,
        .directControlBtn,
        .safeguardBtn {
          background-color: #0e75c4 !important;
          height: 35px;
          padding: 0px 5px;
          font-size: 12px;
          margin-bottom: 10px;
          .p-button-label {
            font-weight: normal;
          }
        }
      }
    }

    /* Apply a border to the right of all but the last column */
    table th:not(:last-child),
    table td:not(:last-child) {
      border-right: 1px solid #0e75c4;
    }

    /* Apply a border to the bottom of all but the last row */
    table > thead > tr:not(:last-child) > th,
    table > thead > tr:not(:last-child) > td,
    table > tbody > tr:not(:last-child) > th,
    table > tbody > tr:not(:last-child) > td,
    table > tfoot > tr:not(:last-child) > th,
    table > tfoot > tr:not(:last-child) > td,
    table > tr:not(:last-child) > td,
    table > tr:not(:last-child) > th,
    table > thead:not(:last-child),
    table > tbody:not(:last-child),
    table > tfoot:not(:last-child) {
      border-bottom: 1px solid #0e75c4;
    }
  }
}
