main{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .header_wrapper_layout{
        height: 38px;   //height of the header
    }
    .main_wrapper{
        // overflow: auto;
        // height: calc(100vh - 96px);  
    }
}
