.loader-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.loader{
    width: 46px;
    height: 46px;
    border: 7px solid white;
    border-top: 7px solid #0AB14C;
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

@keyframes spin{
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
}