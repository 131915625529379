.p-dialog .p-dialog-header-icons {
    display: none;
  }
  
  .p-dialog {
    width: 607px;
  }
  
  .p-component {
    font-family: "Open Sans", sans-serif !important;
  }
  
  .p-dialog .p-dialog-header {
    padding: .5rem;
  }
  
  .p-dialog .p-dialog-content {
    display: flex;
    justify-content: center;
    color: #03243d;
    font-weight: 600;
    padding-bottom: 0rem;
  }
  
  .warn-popup {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: 1.5rem;
    
    .warnLogo {
      position: absolute;
      left: 35px;
      top: 35px;
      right: 35px;
      height: 50px;
      width: 50px;
      margin-bottom: 10px;
    }
  
    .warnFormWrapperInner {
      display: flex;
      height: 81px;
      width: 100%;
      align-items: center;
  
      p {
        margin: 0;
      }
  
      .warnTitle{
        position: relative;
        left: 90px;
        color: #03243D;
        font-size: 14px;
        font-weight: 600;
      }
    }
  
    .buttonWrapper {
      position: relative;
      right: -12rem;
      margin-left: 2rem;
  
      .warn-button {
        width: 121px;
        height: 32px;
        border-radius: 3px;
      }
  
      .warn-button,
      .warn-button:hover {
        background-color: #0e75c4;
        color: white;
        margin-left: 1rem;
      }
    }
  }
  
  .p-dialog .p-dialog-footer button {
    width: 121px;
  }
  