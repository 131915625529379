/* index.scss */

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

 /* Hide the default body scrollbar */

// .ItemGroup::-webkit-scrollbar {
//   width: 0.5em;
// }

// .ItemGroup::-webkit-scrollbar-track {
//   background-color: transparent;
// }

// .ItemGroup::-webkit-scrollbar-thumb {
//   background-color: transparent;
// }

.ItemGroup {
  padding-left: 98px;
  display: flex;
  flex-direction: column;
  max-height: 300px; /* Set a maximum height for the container */
  max-height: 300px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrollbar when content overflows */
}

.itemForm {
  display: flex;
}

/* AddEquipmentForm.scss */

.contact-error {
  color: red;
  font-size: 12px;
  margin: -25px 8px;
}

.inputBox {
  background: transparent 0% 0% no-repeat padding-box;
  border: 1px solid #1063a27b;
  border-radius: 4px;
  padding-left: 10px;
  opacity: 1;
  background-color: rgba(189, 228, 248, 0.3);
}

.equipment-item {
  padding: 10px;
}

.edit-icon  {
  // padding-left: 44px;
}

.pi.pi-check-circle{
//  margin-left: 46px;
}

.delete-icon {
  padding: 10px 10px 10px 44px;
}


.footer {
  .new-equipment-button {
    width: 125px;
    left: 75.5rem;
    position: sticky;
    border: none;
    border-radius: 3px;
    background-color: #0E75C4;
    color: white;
    font-size: 14px;
    padding: 5px;
  }
}