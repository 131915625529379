@import '../../sassStyles/mixins';

.error_wrapper{
    @include center-element;
    height: 100vh;
    flex-direction: column;

    .error-icon{
        width: 86px;
        height: 86px;
        margin-bottom: 43px;
    }

    .error-heading{
        font-size: 40px;
        font-weight: 600;
        color: #03243D;
    }

    .error-message{
        font-size: 16px;
        font-weight: 600;
        color: #03243D;
        margin-bottom: 56px;
    }

    .home{
        border: 2px solid #0E75C4;
        border-radius: 6px;
        background-color: #E9F6FC;
        color: #0E75C4;
        font-size: 16px;
        font-weight: bold;
        width: 109px;
        height: 44px;
    }

    .logout{
        border: 2px solid #0E75C4;
        border-radius: 6px;
        background-color: #0E75C4;
        color: white;
        font-size: 16px;
        font-weight: bold;
        width: 109px;
        height: 44px; 
        margin-left: 20px;
    }
}