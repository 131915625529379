.publish-title {
  border-bottom: 2px solid #dee2e6;
  height: 47px;

  .publish-header {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
  }
}

.publish-container {
  overflow: auto;
  height: calc(100vh - 105px);
  .publish-wrapper {

    .publish-heading {
      margin: 21px 0px 20px 32px;
      color: #03243d;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .publish-table-wrapper {
    padding: 0px 32px;
    width: 100%;

    .lookup-table-wrapper{
      width: 100%;
    }

    .table-main-heading{
      color: #144870;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 14px;
    }

    .lookup-heading{
      color: #03243D;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .table-heading{
      color: #03243d;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    table {
      border: 1px solid #9dacb6;
      border-radius: 4px;
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin-bottom: 30px;

      .table-heading {
        border-bottom: 1px solid #dbdee0;
      }

      tr {
        border-bottom: 1px solid #dbdee0;
        // .colspan {
        //   padding: 5px;
        // }

        th {
          color: white;
          background-color: #128DDD;
          font-size: 14px;
          padding: 0.6rem 0.5rem;
          font-weight: 600;
        }

        td {
          color: #03243d;
          font-size: 14px;
          padding: 0.4rem 0.5rem;
        }

        td:nth-child(1),
        th:nth-child(1) {
          width: 3%;
        }
        td:nth-child(2),
        th:nth-child(2) {
          width: 10%;
        }
        td:nth-child(3),
        th:nth-child(3) {
          width: 15%;
        }
        td:nth-child(4),
        th:nth-child(4) {
          width: 16%;
        }
        td:nth-child(5),
        th:nth-child(5) {
          width: 16%;
        }
        td:nth-child(6),
        th:nth-child(6) {
          width: 16%;
        }
        td:nth-child(7),
        th:nth-child(7) {
          width: 14%;
        }

        .long-data {
          width: 300px;
          overflow: hidden !important;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .publish-save {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding-bottom: 20px;
    justify-content: space-between;

    .publish-date {
      display: flex;
      flex-direction: row;
      color: #03243d;
      font-size: 14px;
      font-weight: bold;
      align-items: center;

      .date-label {
        margin: 0px 20px 0px 32px;
      }

      .p-calendar-w-btn-right .p-inputtext,
      .p-calendar-w-btn-right .p-datepicker-trigger {
        height: 32px;
      }

      .p-button {
        color: #687782;
        background-color: white;
        border: 1px solid #9dacb6;
      }

      .p-inputtext {
        color: #03243d;
        font-size: 12px;
        border: 1px solid #9dacb6;
      }
    }

    .publish-buttons {
      position: relative;
      bottom: 0;
      margin-right: 44px;

      .cancel-button,
      .cancel-disable,
      .save-button,
      .save-disable {
        width: 121px;
        height: 32px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
      }

      .save-button {
        background-color: #0e75c4;
        color: white;
        border: 1px solid #0e75c4;
      }

      .save-disable {
        background-color: #9dacb6;
        color: white;
        border: 1px solid #9dacb6;
      }

      .cancel-button {
        border: 1px solid #0e75c4;
        background-color: #e9f6fc;
        color: #0e75c4;
        margin-right: 14px;
      }

      .cancel-disable {
        border: 1px solid #1448709a;
        background-color: #03243d1f;
        color: #144870a5;
        margin-right: 14px;
      }
    }
  }
}
