@import "../../../sassStyles/mixins";

.searchbar {
  .searchicon {
    position: absolute;
    margin-left: -20rem;
    z-index: 1;
    font-size: 1.125rem;
  }

  .pi-times-circle {
    position: absolute;
    cursor: pointer;
    margin-left: 19.7rem;
    z-index: 1;
    color: #9dacb6;
    font-size: 1.5rem;
    font-weight: lighter;
  }
  .pi-times-circle:hover {
    color: #80909b;
  }
  .pi-times-circle:active {
    color: #80909b;
    transform: translateY(1px);
  }

  .inputElement {
    color: #5f5f5f;
    border-radius: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .inputElement::-webkit-input-placeholder {
    font-size: 14px;
    color: #445968;
    font-style: italic;
  }
  .inputElement::-ms-input-placeholder {
    font-size: 14px;
    color: #445968;
    font-style: italic;
  }
  .inputElement::-moz-placeholder {
    font-size: 14px;
    color: #445968;
    font-style: italic;
  }
  .inputElement:focus {
    outline: none;
    box-shadow: 0 0 4px 2px #79aee6;
    transition: 0.2s ease-in;
  }
}

.searchDataList {
  cursor: pointer;
  font-size: 13px;
  margin-top: 5px;
  background-color: #fff;
  height: fit-content;
  max-height: 200px;
  border-radius: 6px;
  z-index: 2;
  overflow-y: scroll;
  position: absolute;
  @include scrollbar-style;
}
