.swi-mapping {
  .dropDown-swi1 {
    width: 191px;
    margin: 10px 30px;
    height: 32px;
  }
  .dropDown-swi2 {
    width: 436px;
    margin: 10px 30px;
    height: 32px;
  }
}

.p-dropdown-panel .p-component .p-ripple-disabled .p-connected-overlay-enter-done {
  width: 431px;
  z-index: 1005;
  /* min-width: 435.996px; */
  transform-origin: center top;
  top: 233.191px;
  left: 354.996px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  color: #144870;
  font-weight: bold;
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  /* color: #495057; */
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  flex: 1 1 auto;
  width: 1%;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 4px;
  color: #144870;
  font-weight: bold;
}

.dropDown {
  width: 400px;
  margin: 10px 30px;
}

.table-container {
  max-height: 385px; /* Adjust the maximum height as needed */
  overflow-y: auto;
  position: relative;
  overflow-x: hidden;
}
.custom-table {
  border: 1px solid #ccc;
  width: 98%;
  border-collapse: collapse;
  margin: 10px;
  border-radius: 10px;

  
  .swi-header {
    background-color: #128DDDC7;
  }
  .swi-content {
    background-color: #E9F6FC9A;
    .swiIcon {
      width: 64px;
        height: 91px;
        position: relative;
        left: 110px;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
      background-color:#E9F6FC9A;
      color: #11415B;  
      border: none;  }
  }
 

  th {
    color: #FFFFFF;
    padding: 0px;
    text-align: left;
    top: 0; /* Add this line */
    z-index: 100; /* Add this line */
  }

  .p-accordion .p-accordion-header .p-accordion-header-link {
    border: none;
    height: 20px;
    background-color: white;
    color: #11415B;
  }

  textarea {
    width: 70%;
    height: 50px;
  }
  .multi-line-text {
    display: block;
    white-space: pre-line; /* Preserve line breaks */
    line-height: 1.5; /* Adjust line height as needed */
    max-width: 64ch;
    word-wrap: break-word;
  }

  .button-container {
    display: flex;
    justify-content: flex-end; /* Align buttons to the left */
    align-items: flex-start; /* Align buttons to the top */

    .edit-disable{
      position: relative;
      top: 10px;
      cursor: pointer;
      width: 20px;
      right: 16px;
    }

    .cancel-button{
      cursor: pointer;
      width: 20px;
      position: relative;
      top: 10px;
      right: 7px;
    
    }
 .edit-icon-swi {
    cursor: pointer;
    width: 15px;
    margin-right: 10px;
    margin-top: 4px;
    margin-top: 11px;
  }
  .deleteicon{
    cursor: pointer;
      width: 15px;
      margin-right: 10px;
      margin-top: 4px; 
      margin-top: 11px;
  }
  .newnotification {
    height: 28px;
    bottom: -16px;
    position: relative;
    left: -11px;
    color: white;
    background: #687782;
  }
   
    .save-button {
      cursor: pointer;
      width: 20px;
    }
    .cancel-button{
      cursor: pointer;
      width: 20px;
      position: relative;
      top: 10px;
      right: 7px;

    }
  }
  .accordian-content {
    margin-top: -20px;
    padding: 21px;
    border: 1px solid #0E75C4;
  }
}

.custom-table th,
.custom-table td {
  border: 1px solid #0E75C4;
  padding: 8px;
  font-weight: 600;
}

.Accordian-Header{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
button{
  margin-left: 10px;
  background-color: #687782; 
  color: white;
  white-space: nowrap; 
  height: 22px;
  font-family: Open sans;
  span {
    display: inline-block; /* Display span as inline-block */
    vertical-align: middle; /* Align text vertically within span */
    margin-right: 5px; /* Add right margin to separate text from the icon */
  }
}
}