@import '../../sassStyles/variables';

.header_logo {
  width: 20%;
  img {
    position: relative;
    left: 34px;
    width: 86px;
    height: 36px;
    display: inline-flex;
  }
}

@media only screen and (max-width: $mobileMaxWidth){
    .header_logo {
      img {
        width: 150px;
        height: 20px;
      }
    }
}
