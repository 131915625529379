.common{
    overflow: auto;
    height: calc(100vh - 147px);
    margin-left: 3.5rem;
    // margin-top: 1.25rem;

    .common-title{
        font-weight: bold;
        margin-top: 2.5rem;
        font-size: 14px;
        color: #03243D;
    }
        
}