.gas-content {
  overflow: auto;
  height: calc(100vh - 190px);
  margin-left: 3.5rem;

  .gas-sections {
    margin-top: 10px;
  }
}
.gas-heading {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: 2.5rem;
  font-size: 14px;
  color: #03243d;
}
