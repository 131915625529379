.template-title {
  border-bottom: 1px solid #dee2e6;

  .template-header {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
  }
}

.template-content {
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: inset 0 0 0 0;
  }

  .p-tabview .p-tabview-panels {
    padding: 0;
  }

  .p-tabview-nav-content {
    height: 44px;
    border: 1px solid #dee2e6;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #213848;
    font-size: 16px;
    font-weight: bold;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #213848;
    font-size: 16px;
    font-weight: 400;
  }

  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    background-color: #0e75c4;
  }

  .p-component {
    font-family: "Open Sans", sans-serif !important;
  }
}

.template-content {
  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    margin-left: 2rem;
  }

  .p-tabview .p-tabview-nav {
    margin-left: 2rem;
  }
}

.template-title {
  .publish-wrapper {
    overflow: auto;
    height: calc(100vh - 172px);

    .publish-heading {
      margin: 21px 0px 20px 32px;
      color: #03243d;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .publish-table-wrapper {
    padding: 0px 32px;
    width: 100%;

    table {
      border: 1px solid #9dacb6;
      border-radius: 4px;
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;

      .table-heading {
        border-bottom: 1px solid #dbdee0;
      }

      tr {
        border-bottom: 1px solid #dbdee0;
        .colspan {
          padding: 5px;
        }

        th {
          color: white;
          background-color: #1063a2;
          font-size: 14px;
          padding: 0.6rem 0.8rem;
          font-weight: 600;
        }

        td {
          color: #03243d;
          font-size: 14px;
          padding: 0.4rem 0.8rem;
          // display: block;
        }

        td:nth-child(1),
        th:nth-child(1) {
          width: 2%;
        }
        td:nth-child(2),
        th:nth-child(2) {
          width: 8%;
        }
        td:nth-child(3),
        th:nth-child(3) {
          width: 15%;
        }
        td:nth-child(4),
        th:nth-child(4) {
          width: 18%;
        }
        td:nth-child(5),
        th:nth-child(5) {
          width: 18%;
        }
        td:nth-child(6),
        th:nth-child(6) {
          width: 15%;
        }
        td:nth-child(7),
        th:nth-child(7) {
          width: 14%;
        }

        .long-data {
          width: 300px !important;
          overflow: hidden !important;
          // white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .publish-save {
    display: flex;
    align-items: center;
    border-top: 2px solid #dee2e6;
    width: 100%;
    position: fixed;
    height: 70px;
    bottom: 0;
    z-index: 36;
    background-color: white;

    .publish-date {
      display: flex;
      flex-direction: row;
      color: #03243d;
      font-size: 14px;
      font-weight: bold;
      align-items: center;

      .date-label {
        margin: 0px 20px 0px 32px;
      }

      .p-calendar-w-btn-right .p-inputtext,
      .p-calendar-w-btn-right .p-datepicker-trigger {
        height: 32px;
      }

      .p-button {
        color: #687782;
        background-color: white;
        border: 1px solid #9dacb6;
      }

      .p-inputtext {
        color: #03243d;
        font-size: 12px;
        border: 1px solid #9dacb6;
      }
    }

    .publish-buttons {
      position: fixed;
      right: 0;
      margin-right: 27px;

      .cancel-button,
      .cancel-disable,
      .save-button,
      .save-disable {
        width: 121px;
        height: 32px;
        font-size: 14px;
        font-weight: bold;
        border-radius: 3px;
      }

      .save-button {
        background-color: #0e75c4;
        color: white;
        border: 1px solid #0e75c4;
      }

      .save-disable {
        background-color: #9dacb6;
        color: white;
        border: 1px solid #9dacb6;
      }

      .cancel-button {
        border: 1px solid #0e75c4;
        background-color: #e9f6fc;
        color: #0e75c4;
        margin-right: 14px;
      }

      .cancel-disable {
        border: 1px solid #1448709a;
        background-color: #03243d1f;
        color: #144870a5;
        margin-right: 14px;
      }
    }
  }
}
