.electric-content {
  overflow: auto;
  height: calc(100vh - 190px);
  margin-left: 3.5rem;

  .electric-sections {
    margin-top: 10px;
  }
}
.electric-heading {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: 1.25rem;
  font-size: 14px;
  color: #03243d;
}
