@import "../../sassStyles/mixins";

.auth-wrapper {
  width: 100vw;
  height: 100vh;
  @include center-element;
  background: url("../../Assets/Images/login_image.png");

  .login-section {
    width: 346px;
    height: 445px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    .es-logo{
      // margin-bottom: 15px;
      width: 198px;
      height: 83px;
    }
    .admin-portal{
      font-size: 18px;
      font-weight: bold;
      color: #144870;
      margin-bottom: 50px;

    }
    .from{
      font-size: 16px;
      font-weight: bold;
      color: #03243D;
    }
    .es-image{
      width: 148px;
      height: 17px;
      margin-bottom: 35px;
    }

    .login-button{
      width: 238px;
      height: 46px;
      border-radius: 24px;
      background-color: #1063A2;
    }
  }
}
