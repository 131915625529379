  .scroll{
    overflow: auto;
    height: calc(93vh - 250px);
    flex-direction: column-reverse;
  }
 
  .noscroll{
    overflow: hidden !important;
    height: fit-content !important;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    top: 245px;
  }
  
  .ppe-wrapper {
    
    .grouptext {
      display: flex;      
      left: 4rem;
      top: 15rem;
      width: 400px;
      height: 32px;
      font-weight: bold;
      color: #144870;
    }
    .dropdown {
      //display: flex;
      position: relative;
      //left: 1rem;
      //top: 15rem;
      background-color: white;
  
      .location {
        font-size: 14px;
        font-weight: bold;
        color: #144870;
        margin-top: 6px;
      }
  
      .select-group {
        display: flex;
        align-items: center;
        padding-left: 5px;
        width: 257px;
        height: 32px;
        color: #144870;
        font-size: 14px;
        font-weight: bold;
        border: 1px solid #9dacb6;
        border-radius: 4px;
       // margin-left: 15px;
        position: relative;
      }
      .dropdown-caret {
        color: #144870;
        position: relative;
        top: -25px;
        margin-left: 230px;
      }
  
      .groups {
        position: absolute;
        color: #144870;
        font-size: 14px;
        font-weight: bold;
        //right: 1rem;
        top: 37px;
        border: 1px solid #9dacb6;
        border-radius: 4px;
        width: 257px;
        background-color: white;
  
        .option-group {
          padding: 5px 3px;
          color: #1063A2;
          font-size: 14px;
          font-weight: 600;
        }
  
        .option-group:hover {
          cursor: pointer;
        }
      }
  
      select:focus {
        outline: none;
      }
    }
    .ppe-data {
      margin-left: 3rem;
      margin-top: 2rem;
      width: 95%;    

    }
  
    tr.ppe-details > td {
      padding-bottom: 15px;
    }
  
    .ppe-details {
      td:nth-child(1) {
        width: 35%;
      }
      td:nth-child(2) {
        width: 12%;
      }
      td:nth-child(3) {
        width: 5%;
      }
  
      .contact-name {
        font-size: 14px;
        color: #213848;
      }
  
      .contact-number {
        font-size: 14px;
        color: #213848;
        font-weight: bold;
      }
  
      
      .contact-edit {
        .edit-icon{
          width: 20px;
          height: 20px;
        }
      }
  
      .contact-delete {
        .trash-icon{
        width: 20px;
        height: 20px;
        }
      }
  
      .edit-enable, .edit-disable{
        width: 24px;
        height: 24px;
      }
  
      .new-duplicate{
        position: relative;
        top: .7rem;
        font-size: 12px;
        color: red;
      }
      .edit-duplicate{
        position: relative;
        top: .7rem;
        font-size: 12px;
        color: red;
      }
    }
    .check-enable {
      color: #687782;
      width: 20px;
      height: 20px;
    }
  
    .check-disable {
      color: lightgrey;
      width: 20px;
      height: 20px;
    }
  
    .close-button{
      .cancel-icon{
        width: 24px;
        height: 24px;
      }
    }
  
    .edit-name,
    .edit-number,
    .new-name,
    .new-number {
      padding-left: 5px;
      font-size: 12px;
      height: 32px;
      border: 1px solid #9dacb6;
      border-radius: 4px;
      color: #144870;
      margin-bottom: 8px;
    }
  
    .edit-name,
    .new-name {
      width: 290px;
    }
  
    .edit-number,
    .new-number {
      width: 125px;
    }
  }
  
  input:focus {
    outline: none;
  }
  
  .footerPEE {
    display: flex;
    align-items: center;
    border-top: 2px solid #dee2e6;
    width: 100%;
    position: fixed;
    height: 63px;
    bottom: 0;
    z-index: 36;
    background-color: white;
    .new-ppe-button {
      width: 125px;
      left: 75.5rem;
      position: sticky;
      border: none;
      border-radius: 3px;
      background-color: #0E75C4;
      color: white;
      font-size: 14px;
      padding: 5px;
    }
  
    .new-contact-button-inactive {
      width: 125px;
      left: 75.5rem;
      position: sticky;
      border: none;
      border-radius: 3px;
      background-color: #9dacb6;
      color: white;
      font-size: 14px;
      padding: 5px;
    }
  }
  