.input-content {
  display: flex;
  flex-direction: row;
  padding-left: 40px;
  margin: 15px 0;

  .input-text {
    width: 30%;
    height: 32px;
    margin-right: 50px;
    border: 1px solid #1063a27b;
    background-color: #bde4f84d;
    border-radius: 4px;
    color: #03243d;
    font-size: 14px;
    padding-left: 10px;
    text-overflow: ellipsis;
  }

  .input-text:focus {
    outline: none;
  }

  .edit-icon {
    width: 24px;
    height: 24px;
  }
}

.edit-content {
  display: flex;
  padding-left: 40px;
  margin: 10px 0;

  .temp-input {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-right: 50px;
  }

  .edit-input {
    width: 100%;
    height: 32px;
    margin-right: 50px;
    border: 1px solid #9dacb6;
    background-color: white;
    border-radius: 4px;
    color: #03243d;
    font-size: 14px;
    padding-left: 10px;
  }

  .edit-input:focus {
    outline: none;
  }

  .temp-error {
    font-size: 12px;
    color: red;
    margin-bottom: 0;
  }

  .check-enable {
    color: green;
    cursor: pointer;
  }

  .check-disable {
    color: lightgray;
  }

  .edit-enable,
  .edit-disable {
    width: 24px;
    height: 24px;
  }

  span {
    margin-right: 15px;

    .cancel-icon {
      width: 24px;
      height: 24px;
    }

    i {
      width: 24px;
      height: 24px;
    }
  }
}
