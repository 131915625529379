@import "../../../sassStyles/mixins";

.swiMapping_Container {
  .swiMapping {
    .dropDown_Container {
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
      padding: 12px 0px 12px 22px;
      margin-bottom: 20px;

      .dropDowns {
        display: flex;
        gap: 170px;

        .dropDownJob,
        .dropDownTask {
          display: flex;
          gap: 18px;

          .p-dropdown {
            height: 32px;

            .p-dropdown-label.p-placeholder {
              display: flex;
              align-items: center;
            }

            .p-inputtext {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #144870;
              font-weight: bold;
            }
          }

          .addJobBtn,
          .addTaskBtn {
            background-color: #687782;
            border: none;
            height: 34px;
            width: 94px;
          }
        }
      }
    }

    .selectedTask_Container {
      display: flex;
      margin: 30px 0px 0px 10px;

      .selectedTask {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        width: fit-content;
        height: 32px;
        padding: 15px;
        font-size: 14px;
        border-radius: 4px;
        color: #03243d;
        background-color: #bde4f84d;
        border: 1px solid #1063a27b;
      }

      .selectedTask-edit {
        .edit-icon {
          padding: 0 20px;
          cursor: pointer;
        }
      }

      .selected-task {
        .edit-name {
          border-radius: 6px;
          border: 1px solid #ced4da;
          color: #5f5f5f;
          font-size: 14px;
          width: 230px;
          height: 35px;
          padding-left: 15px;
        }

        .cancel-icon,
        .edit-enable {
          margin-left: 10px;
          cursor: pointer;
        }

        .edit-disable {
          margin-left: 10px;
        }
      }
    }

    .addHighEnergyContainer {
      display: flex;
      gap: 20px;
      margin-left: 20px;
      .highEnergyTaskBtn {
        background-color: #0e75c4 !important;
        height: 35px;
        padding: 0px 5px;
        font-size: 12px;
        cursor: pointer;

        .p-button-label {
          font-weight: normal;
        }
      }

      .searchBoxValidationContainer {
        display: inherit;
        flex-direction: column;
        .searchBoxContainer {
          display: inherit;
          align-items: center;

          .cancel-icon {
            margin-right: 10px;
            margin-left: 10px;
          }

          .edit-disable {
            height: 20px;
          }

          .cancel-icon,
          .edit-enable {
            cursor: pointer;
            height: 20px;
          }
        }

        .edit-duplicate {
          font-size: 12px;
          color: red;
        }
      }
    }

    .tableWrapper {
      margin: 20px 10px;
      overflow: auto;
      height: 55vh;
      @include scrollbar-style;

      table {
        border-spacing: 0;
        border-collapse: separate;
        border-radius: 10px;
        border: 1px solid #0e75c4;
        width: 100%;
        position: relative;
        z-index: 1;

        thead {
          th {
            background-color: #128dddc7;
            color: #ffffff;
            font-size: 14px;
            padding: 10px;
          }
        }

        tbody {
          // position: relative;

          .emptyRow {
            height: 300px;
            .emptyColumn {
              width: 25%;
            }
          }

          td {
            padding: 10px;
            background-color: #e9f6fc9a;
            color: #445968;
            font-size: 12px;
            vertical-align: top;

            .edit-duplicate {
              color: red;
            }

            ol {
              margin-left: -15px;
              max-width: 320px;
              word-break: break-all;

              li {
                list-style-type: disc;
              }
            }

            .imagePath-edit {
              display: flex;

              .swiIconImage {
                height: 125px;
                margin-top: 10px;
                object-fit: contain;
              }

              .edit-icon,
              .trash-icon {
                margin-top: 45px;
                cursor: pointer;
              }
            }

            .upload-section-button {
              .times-icon,
              .enable-edit,
              .disable-edit {
                height: 15px;
              }
            }

            .highenergy-task-edit-container,
            .hazard-edit-container,
            .directcontrol-edit-container,
            .safeguard-edit-container {
              display: flex;
              gap: 10px;
              align-items: center;
              position: relative;
              .edit-highenergy-name,
              .edit-hazard-name,
              .edit-directcontrol-name,
              .edit-safeguard-name {
                .edit-name {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  border-radius: 6px;
                  border: 1px solid #ced4da;
                  color: #5f5f5f;
                  font-size: 13px;
                  width: 230px;
                  height: 30px;
                }
              }
              .edit-buttons {
                display: flex;
                gap: 10px;
              }

              .word-limit-error {
                position: absolute;
                top: 30px;
                color: red;
                font-size: 11px;
              }
            }

            .imagePath-edit,
            .highEnergyTask-edit,
            .highEnergyTask-delete,
            .hazard-edit,
            .hazard-delete,
            .directControl-edit,
            .directControl-delete,
            .itemSafeguard-edit,
            .itemSafeguard-delete {
              .edit-icon,
              .trash-icon {
                height: 12px;
                padding: 0 10px;
                cursor: pointer;
              }
              .pi-ellipsis-v {
                margin-top: 16px;
              }
            }

            .SWIIconBtn {
              .upload-section-button {
                .enable-edit,
                .disable-edit {
                  height: 12px;
                  padding: 0 10px;
                  cursor: pointer;
                }
              }
            }

            .SWIIconBtn,
            .hazardBtn,
            .directControlBtn,
            .safeguardBtn {
              background-color: #0e75c4 !important;
              height: 35px;
              padding: 0px 5px;
              font-size: 12px;
              margin-bottom: 10px;
              cursor: pointer;
              .p-button-label {
                font-weight: normal;
              }
            }

            .search-edit-container {
              position: relative;
              display: flex;
              align-items: center;
              gap: 10px;
            }

            .cancel-icon,
            .edit-enable,
            .edit-disable {
              height: 15px;
            }
            .cancel-icon,
            .edit-enable {
              cursor: pointer;
            }
          }
        }
      }

      /* Apply a border to the outside of first and last column*/
      table thead tr:first-child th:first-child {
        border-top-left-radius: 10px;
      }
      table thead tr:first-child th:last-child {
        border-top-right-radius: 10px;
      }

      table tr:last-child td:first-child {
        border-bottom-left-radius: 10px;
      }
      table tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
      }

      /* Apply a border to the right of all but the last column */
      table th:not(:last-child),
      table td:not(:last-child) {
        border-right: 1px solid #0e75c4;
      }

      /* Apply a border to the bottom of all but the last row */
      table > thead > tr:not(:last-child) > th,
      table > thead > tr:not(:last-child) > td,
      table > tbody > tr:not(:last-child) > th,
      table > tbody > tr:not(:last-child) > td,
      table > tfoot > tr:not(:last-child) > th,
      table > tfoot > tr:not(:last-child) > td,
      table > tr:not(:last-child) > td,
      table > tr:not(:last-child) > th,
      table > thead:not(:last-child),
      table > tbody:not(:last-child),
      table > tfoot:not(:last-child) {
        border-bottom: 1px solid #0e75c4;
      }
    }
  }
}

.p-dropdown-panel {
  .trash-icon {
    margin-left: 1rem;
    height: 20px;
    vertical-align: sub;
  }
}

//Common style for all dropdown scrollbars
.p-dropdown-items-wrapper {
  @include scrollbar-style;
}
