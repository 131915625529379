.critical-contacts .p-tabview .p-tabview-panels {
  overflow: auto;
  height: calc(93vh - 208px);
}

.contact-error {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 245px;
}

.critical-contacts {
  .dropdown {
    display: flex;
    position: absolute;
    right: 3rem;
    top: 9.5rem;

    .location {
      font-size: 14px;
      font-weight: bold;
      color: #144870;
      margin-top: 6px;
    }

    .select-location {
      display: flex;
      align-items: center;
      padding-left: 5px;
      width: 257px;
      height: 32px;
      color: #144870;
      font-size: 14px;
      font-weight: bold;
      border: 1px solid #9dacb6;
      border-radius: 4px;
      margin-left: 15px;
      position: relative;
    }
    .dropdown-caret {
      color: #144870;
      position: relative;
      top: 5px;
      right: 1.5rem;
    }

    .cities {
      position: absolute;
      color: #144870;
      font-size: 14px;
      font-weight: bold;
      right: 1rem;
      top: 37px;
      border: 1px solid #9dacb6;
      border-radius: 4px;
      width: 257px;

      .option-location {
        padding: 5px 3px;
        color: #1063A2;
        font-size: 14px;
        font-weight: 600;
      }

      .option-location:hover {
        cursor: pointer;
      }
    }

    select:focus {
      outline: none;
    }
  }
  .critical-data {
    margin-left: 3rem;
    margin-top: 2rem;
    width: 95%;
  }

  tr.contact-details > td {
    padding-bottom: 15px;
  }

  .contact-details {
    td:nth-child(1) {
      width: 35%;
    }
    td:nth-child(2) {
      width: 12%;
    }
    td:nth-child(3) {
      width: 5%;
    }

    .contact-name {
      font-size: 14px;
      color: #213848;
    }

    .contact-number {
      font-size: 14px;
      color: #213848;
      font-weight: bold;
    }

    
    .contact-edit {
      .edit-icon{
        width: 20px;
        height: 20px;
      }
    }

    .contact-delete {
      .trash-icon{
      width: 20px;
      height: 20px;
      }
    }

    .edit-enable, .edit-disable{
      width: 24px;
      height: 24px;
    }

    .new-duplicate{
      position: relative;
      top: .7rem;
      font-size: 12px;
      color: red;
    }
    .edit-duplicate{
      position: relative;
      top: .7rem;
      font-size: 12px;
      color: red;
    }
  }
  .check-enable {
    color: #687782;
    width: 20px;
    height: 20px;
  }

  .check-disable {
    color: lightgrey;
    width: 20px;
    height: 20px;
  }

  .close-button{
    .cancel-icon{
      width: 24px;
      height: 24px;
    }
  }

  .edit-name,
  .edit-number,
  .new-name,
  .new-number {
    padding-left: 5px;
    font-size: 12px;
    height: 32px;
    border: 1px solid #9dacb6;
    border-radius: 4px;
    color: #144870;
    margin-bottom: 8px;
  }

  .edit-name,
  .new-name {
    width: 290px;
  }

  .edit-number,
  .new-number {
    width: 125px;
  }
}

input:focus {
  outline: none;
}

.footer {
  .new-contact-button {
    width: 125px;
    left: 75.5rem;
    position: sticky;
    border: none;
    border-radius: 3px;
    background-color: #0E75C4;
    color: white;
    font-size: 14px;
    padding: 5px;
  }

  .new-contact-button-inactive {
    width: 125px;
    left: 75.5rem;
    position: sticky;
    border: none;
    border-radius: 3px;
    background-color: #9dacb6;
    color: white;
    font-size: 14px;
    padding: 5px;
  }
}
