@import "../../sassStyles/variables";
@import "../../sassStyles/mixins";

.header_container {
  background-color: $headerBackground;
  @include center-element-vertical;
  height: 58px;
  width: 100%;
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;

  .admin-portal{
    position: absolute;
    left: 130px;
    top: 20px;
    color: white;
    font-size: 14px;
    font-weight: 600;
  }

  .header_list {
    margin-left: auto;
    display: flex;
    top: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .header_container .header_list {
    top: 30px;
  }
}
