.notification-title {
  border-bottom: 2px solid #dee2e6;

  .notification-header {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
    position: relative;
  }
}

.notification-content {
  height: calc(100vh - 105px);
  overflow-y: auto;
  padding: 10px 20px;
}

.create-notification {
  padding: 0 1rem;
  position: relative;
  .section-heading {
    font-weight: bold;
    padding-top: 10px;
    font-size: 14px;
    color: #03243d;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    padding-left: 30px;

    tr.notif-input > td {
      padding-bottom: 15px;
    }

    .notif-input {
      .dropdown {
        display: flex;
        flex-direction: column;
        position: relative;
        border: 1px solid #9dacb6;
        border-radius: 4px;
        width: 55%;
        height: 32px;

        .dropdown-wrapper {
          display: flex;
          flex-direction: row;
          padding: 5px 10px;

          .recipients {
            width: 95%;
            font-size: 14px;
            font-weight: bold;
            color: #144870;
          }

          .dropdown-caret {
            i {
              position: relative;
            }
          }
        }

        .dropdown-select {
          background-color: white;
          height: fit-content;
          width: 100%;
          border: 1px solid #9DACB6;
          z-index: 1;
          position: absolute;
          top: 31px;
          padding: 5px 10px;

          .recipient-option{
            font-size: 14px;
            font-weight: bold;
            color: #0e75c4;
            margin-bottom: 5px;
          }
        }
      }
      .label-name {
        font-size: 14px;
        color: #03243d;
      }

      input {
        height: 32px;
        border: 1px solid #9dacb6;
        color: #2f4656;
        font-size: 12px;
        padding-left: 10px;
      }
      .notif-error {
        width: 55%;
        border: 1px solid red;
      }

      .name-error,
      .msg-error {
        font-size: 12px;
        color: red;
        margin: 0;
      }
    }

    .notif-name,
    .dropdowns {
      width: 55%;
    }

    span {
      margin-right: 10px;
    }

    .file-row {
      display: flex;
      align-items: center;

      input[type="file"] {
        display: none;
      }

      .file-upload-button {
        border-radius: 3px;
        padding: 4px 10px;
        margin-right: 10px;
        font-weight: bold;
        font-size: 14px;
        background-color: #687782;
        color: white;
        width: fit-content;
      }

      .file-error{
        font-size: 12px;
        color: red;
        margin: 0;
      }

      .file-name {
        position: relative;
        font-size: 14px;
        top: 6px;
      }
    }
    .file-support {
      margin-bottom: 0;
      margin-top: 5px;
      color: #03243d;
      font-size: 14px;
    }

    .date-input {
      .p-inputtext {
        padding: 0 5px;
        border-radius: 0;
        font-size: 14px;
        color: #03243d;
      }

      span {
        height: 32px;
        font-size: 14px;
        color: #03243d;
      }
    }

    .msg-label {
      position: relative;
      top: -2rem;
      font-size: 14px;
      color: #03243d;
    }

    textarea {
      border: 1px solid #9dacb6;
      border-radius: 4px;
      color: #2f4656;
      font-size: 12px;
      padding-left: 10px;
    }

    .error {
      border: 1px solid red;
    }

    input:focus,
    textarea:focus {
      outline: none;
    }

    .p-datepicker {
      width: 0;
      height: 500px;
    }
  }
}

.notification-button {
  position: absolute;
  top: 6px;
  right: 20px;

  button {
    width: 7rem;
    font-size: 14px;
    margin-left: 10px;
    border-radius: 3px;
    padding: 3px;
    font-weight: bold;
  }
  .clear,
  .save-draft {
    border: 1px solid #0e75c4;
    background-color: #e9f6fc;
    color: #0e75c4;
  }

  .send {
    border: none;
    background-color: #0e75c4;
    color: white;
  }

  .clear-disabled,
  .save-draft-disabled {
    border: 1px solid #1448709a;
    background-color: #03243d1f;
    color: #144870a5;
  }

  .send-disabled {
    border: none;
    background-color: #9dacb6;
    color: white;
  }
}

.notification-table {
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #213848;
    font-weight: bold;
    border-color: #0E75C4;
  }

  .p-tabview .p-tabview-nav .p-tabview-ink-bar {
    height: 3px;
    background-color: #0E75C4;
  }

  .p-tabview .p-tabview-nav {
    border: none;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #213848;
    font-weight: 400;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
  }

  .p-tabview .p-tabview-panels {
    padding: 0.75rem;
  }
}

.notif-table {
  table {
    border: 1px solid #9dacb6;
    border-radius: 4px;
    border-collapse: collapse;

    .table-heading {
      border-bottom: 1px solid #dbdee0;

      th {
        font-size: 14px;
        color: #03243d;
        font-weight: 600;
        padding: 5px 6px;
      }

      .heading-icon {
        display: flex;
        flex-direction: row;
        align-items: center;

        i {
          font-size: 0.75rem;
          margin-left: 3px;
        }
      }
    }

    tr {
      border-bottom: 1px solid #dbdee0;
      .colspan {
        padding: 0;
      }

      td {
        font-size: 14px;
        color: #03243d;

        .trash-icon {
          width: 20px;
          height: 22px;
        }
      }
      td:nth-child(1){
        width: 5%;
      }
      td:nth-child(2) {
        width: 6%;
      }
      td:nth-child(3) {
        width: 10%;
      }
      td:nth-child(4) {
        width: 27%;
      }
      td:nth-child(5) {
        width: 12%;
      }
      td:nth-child(6) {
        width: 28%;
      }
      td:nth-child(7),
      td:last-child {
        width: 5%;
      }
    }

    td {
      padding: 0.5rem 9px;
    }

    .pi-trash {
      margin-left: 5px;
    }
  }
}

.p-datepicker {
  table {
    padding: 0;

    td {
      padding: 0;
    }
  }

  .p-datepicker-header {
    padding: 0;
  }

  .p-timepicker {
    padding: 0;

    span {
      font-size: 0.75rem;
    }

    button {
      width: 1rem;
      height: 1rem;
    }
  }
}
