.p-dialog .p-dialog-header-icons {
  display: none;
}

.p-dialog {
  width: 607px;
  height: 170px;
}

.p-dialog .p-dialog-header {
  padding: 1rem;
}

.p-dialog .p-dialog-content {
  display: flex;
  justify-content: center;
  color: #03243d;
  font-weight: 600;
  padding-bottom: 0rem;
}

.success-popup {
  .successLogo {
    height: 80px;
    width: 105px;
  }
}

.popup-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 1.5rem;

  .popup-display {
    display: flex;
    height: 84px;
    align-items: center;
    justify-content: center;

    .popup-data {
      display: inline-block;

      p {
        margin: 0;
      }
    }

    .popup-content {
      display: inline-block;
      position: relative;
      left: 39px;
      margin-top: 5px;
      width: 481px;
    }
    .screen-data {
      font-weight: bold;
    }

    .popup-image {
      position: absolute;
      height: 50px;
      width: 50px;
      left: 35px;
      top: 35px;
      right: 35px;
    }
  }

  .popupButtonWrapper {
    position: relative;
    // margin-top: 20px;
    right: -17rem;
    margin-left: 2rem;
    width: 260px;

    .cancel-button,
    .delete-button,
    .save-button {
      width: 121px;
      height: 32px;
      border-radius: 3px;
    }

    .cancel-button,
    .cancel-button:hover {
      background-color: #e9f6fc;
      color: #0e75c4;
      border: 1px solid #0e75c4;
    }

    .delete-button,
    .save-button {
      background-color: #0e75c4;
      color: white;
      margin-left: 1rem;
    }
  }
}

.p-dialog .p-dialog-footer button {
  width: 121px;
}
