.option-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.PM {
  .dropdown-section {
    display: flex;
    gap: 40px;
    margin: 10px 74px;
    .dropdown {
      width: 300px;
    }
  }

  .Error-Span{
    color: red;
  }
  .title-div {
    margin: 10px 84px;
  }
  .selected-work-task {
    margin: 30px 74px;
    display: flex;
    gap: 16px;
    align-items: center;
    span{
      border: 1px solid black;
      padding: 0px 20px;
      width: 275px;
      height: 32px;
      border-radius: 5px;
      background-color: aliceblue;
    }
    input{
      padding-left: 10px;
      width: 275px;
      height: 32px;
      border-radius: 5px;
    }
    .pi {
      margin-left: 10px;
    }
    img{
      cursor: pointer;
      height: 20px;
      margin-left: 10px;
    }
  }

  .edit-task-name {
    display: flex;
    gap: 20px;
    margin: 10px 10px;
  }
  .edit-task-name-input {
    gap: 20px;
    display: flex;
    margin: 36px 10px 20px 110px;
  }
  .edit-task-name-hidden {
    display: none;
  }
  ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 26px 15px 11px 80px;
    max-height: 200px;
    overflow: auto;
    li {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-start;
      .li-docname {
        width: 350px;
      }
      img{
        cursor: pointer;

        height: 20px;
      }
    }

    input {
      width: 280px;
    }
    button {
      background-color: white;
      border-radius: 20px;
    }
  }

  .content-row {
    display: flex;
    gap: 20px;
  }
  .row-doc-name {
    width: 280px;
  }

  .add-new-doc{

    .add-document-input {
      display: flex;
      gap: 25px;
      align-items: center;
      margin: 16px 10px 10px 109px;
      button {
        background-color: white;
        border-radius: 20px;
      }   
      input {
        width: 300px;
      }
    }
  }
  img{
    cursor: pointer;
  }
  .edit-add{
    height: 20px;
    margin-top: 5px;
  }
}
