.hyperlink-title {
  border-bottom: 2px solid #dee2e6;
  height: 47px;

  .hyperlink-header {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 20px;
  }
}
.resource-image {
  width: 40px;
  height: 38px;
}
.image-avatar {
  left: -31px;
  position: relative;
}
.video-links {
  background-color: #d3eaff;
  border-radius: 13px;
  padding: 9px;
  position: relative;
  right: -54px;
  width: 260px;
  bottom: -5px;
  cursor: pointer;
  font-size: 12px;
  color: #888888;

  .link-container {
    text-decoration: none;
    color: #0e75c4;
    font-weight: bold;

    .attach-link {
      margin-left: 15px;
      padding-top: 2px;
      position: relative;
      bottom: 7px;
      width: 25px;
      height: 25px;
    }
  }
}

.link-content {
  right: 13px;
  position: absolute;
  top: 13px;
}

.newrow-switch {
  left: 14rem;
  position: relative;
  top: 1px;
}
.edit-switch {
  // left: 13.8rem;
  position: relative;
  top: 4px;
}
.hyperlink-container {
  padding-left: 54px;
  padding-top: 15px;
  margin-bottom: 33px;

  td:nth-child(1) {
    width: 5%;
  }

  td:nth-child(2) {
    width: 77%;
  }

  td:nth-child(3),
  td:nth-child(4),
  td:nth-child(5) {
    width: 6%;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tbody {
    // border-bottom: 1px solid black;
    position: relative;
  }

  tr {
    margin-bottom: 10px;
    height: 56px;
  }

  .line-border {
    width: 104%;
    height: 0px;
    border: 1px solid #ebebeb;
    opacity: 0.8;
    margin-top: -3px;
    z-index: 1;
    position: absolute;
    left: -59px;
  }

  .line-edit-border {
    width: 97.3%;
    height: 0px;
    border: 1px solid #ebebeb;
    opacity: 0.8;
    margin-top: 1px;
    z-index: 1;
    position: absolute;
    left: 20px;
  }
}
.Hide {
  display: none;
}
.trash-container {
  // right: -172px;
  position: relative;
  top: 4px;
  display: inline-flex;
  width: 24px;
  height: 24px;
  color: #687782;
  cursor: pointer;
}
.pencil-container {
  top: 4px;
  // right: -106px;
  position: relative;
  display: inline-flex;
  width: 24px;
  height: 24px;
  color: #687782;
  cursor: pointer;
}
.hyperlink-button-active {
  width: 125px;
  left: 75.5rem;
  position: sticky;
  border: none;
  border-radius: 3px;
  background-color: #0e75c4;
  color: white;
  font-size: 14px;
  padding: 5px;
}

.hyperlink-button-inactive {
  width: 125px;
  left: 75.5rem;
  position: sticky;
  color: white;
  border: none;
  background-color: #9dacb6;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px;
}

.switch-container {
  // left: 14rem;
  position: relative;
  top: 7px;
}
.popup-icon {
  width: 120px;
  height: 120px;
  position: relative;
  border: 1px solid white;
  border-radius: 50%;
  margin: 8px;
  right: -189px;
}
.profile-container {
  display: flex;
}
.text-container {
  bottom: 130px;
  left: 42px;
  position: absolute;
}

.video-rows {
  input[type="file"] {
    display: none;
  }

  .image-upload {
    position: relative;
    left: -22px;
    font-size: 1rem;
  }

  margin-top: 20px;
  // img {
  //   width: 30px;
  //   height: 30px;
  // }
  .p-inputtext {
    left: 87px;
    position: relative;
    padding: 3px 5px;
    font-size: 12px;
    width: 260px;
  }
  .new-input {
    left: 16px;
    position: relative;
  }
  span i {
    font-size: 20px;
  }

  .name-error {
    position: relative;
    font-size: 12px;
    color: red;
    top: -1.4rem;
    left: -12.5rem;
  }

  .link-error {
    position: relative;
    font-size: 12px;
    color: red;
    top: -1.4rem;
    left: -13rem;
  }

  .file-edit-error{
    position: absolute;
    font-size: 12px;
    color: red;
    margin: 0;
    left: 2rem;
  }

  .new-close {
    position: absolute;
    width: 24px;
    height: 24px;
  }

  .checkEnable {
    position: relative;
    color: #687782;
  }
  .checkDisable {
    position: relative;
    color: lightgray;
    cursor: pointer;
    height: 24px;
    width: 24px;
  }
  .times-icon {
    position: relative;
    color: #687782;
    height: 24px;
    width: 24px;
  }
}

//for new row
.video-rows {
  input[type="file"] {
    display: none;
  }

  .times-icon {
    top: 4px;
    position: relative;
    display: inline-flex;
    width: 24px;
    height: 24px;
    color: #687782;
  }

  .trash-icon {
    width: 22px;
    height: 24px;
  }

  .edit-icon {
    width: 24px;
    height: 24px;
  }

  .cancel-icon {
    width: 24px;
    height: 24px;
  }

  .enable-edit,
  .disable-edit {
    width: 24px;
    height: 24px;
  }
  // img {
  //   .enable-edit{
  //     width: 24px;
  //     height: 24px;
  //   }
  // }
  .p-inputtext {
    left: 52px;
    position: relative;
    padding: 3px 5px;
    width: 260px;
    font-size: 12px;
  }
  .new-link {
    left: 52px;
    position: relative;
    padding: 3px 5px;
    width: 260px;
    font-size: 12px;
    margin-left: 15px;
  }
  .new-change-link {
    left: -13rem;
    position: relative;
    padding: 3px 5px;
    width: 260px;
    font-size: 12px;
    margin-left: 15px;
  }
  .new-input {
    left: 16px;
    position: relative;
  }
  .new-change-input {
    left: -15rem;
    position: relative;
  }
  span i {
    font-size: 20px;
    display: inline-flex;
    width: 24px;
    height: 24px;
  }
  .upload-icon {
    left: -24px;
    position: relative;
  }
  .checkEnable {
    // left: 84px;
    top: -10px;
    position: relative;
    color: green;
  }
  .checkDisable {
    // left: 84px;
    top: -10px;
    position: relative;
    color: grey;
    cursor: pointer;
  }
}

.hide {
  visibility: hidden;
}

.checkEditEnable {
  // left: 103px;
  top: 4px;
  position: relative;
  color: green;
}
.checkEditDisable {
  // left: 103px;
  top: 4px;
  position: relative;
  color: grey;
  cursor: pointer;
}
.hyperlink {
  height: calc(93vh - 120px);
  overflow-y: auto;
}

.footer {
  display: flex;
  align-items: center;
  border-top: 2px solid #dee2e6;
  width: 100%;
  position: fixed;
  height: 63px;
  bottom: 0;
  z-index: 36;
  background-color: white;
}
