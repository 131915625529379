.hec {
  height: calc(100vh - 152px);
}

.hec-error {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 245px;
}



.hec {
  .edit-duplicate {
    color: red;
    font-size: 12px;
  }

  .dropdown {
    margin: 8px 5px;
    display: flex;
    left: 3rem;    

    .p-dropdown {
      height: 30px;
      margin-left: 15px;
      width: 400px;

      .p-dropdown-label.p-placeholder {
        display: flex;
        align-items: center;
      }

      .p-inputtext {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #144870;
        font-weight: bold;
      }
    }

    .addHighEnergyBtn {
      background-color: #687782;
      border: none;
      height: 29px;
      width: 194px;
      margin-left: 15px;
    }
  }

  .table-container {
    overflow: auto;
    overflow-x: auto;
    max-height: 390px;
    // height: calc(93vh - 190px);
  }

  .hec-data-table {
    padding: 8px 3px;

    table {
      .table-heading {
        border-bottom: 1px solid #dbdee0;

        th {
          font-size: 14px;
          color: white;
          font-weight: 600;
          padding: 5px 6px;
          border: 1px solid #9dacb6;
          background: #5aaff0;

          .pi-ellipsis-v {
            float: right;
          }
        }
      }

      tr {
        border-bottom: 1px solid #dbdee0;
        vertical-align: top;

        .colspan {
          padding: 0;
        }

        td:nth-child(1) {
          width: 7%;
        }

        td:nth-child(2) {
          vertical-align: top;
          width: 20%;
        }

        td:nth-child(3) {
          width: 20%;
        }

        td:nth-child(4) {
          width: 20%;
        }

        td:nth-child(5) {
          width: 20%;
        }
      }

      td {
        padding: 0.5rem 5px;
        border: 1px solid #9dacb6;
      }

      ul {
        margin-left: -2rem;
        list-style-type: none;
      }

      .list {
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;

        .edit-icon,
        .trash-icon {
          width: 15px;
          height: 15px;
          cursor: pointer;
        }

        .cancel-icon,
        .edit-enable {
          cursor: pointer;
        }
      }

      .list p {
        width: 80%;
      }

      .pi-trash {
        margin-left: 5px;
      }

      .div-crud-icon {
        padding: 5px;
      }

    }
  }

  .check-enable {
    color: #687782;
    width: 20px;
    height: 20px;
  }

  .check-disable {
    color: lightgrey;
    width: 20px;
    height: 20px;
  }

  .close-button {
    .cancel-icon {
      width: 24px;
      height: 24px;
    }
  }

  .edit-name,
  .edit-number,
  .new-name,
  .new-number {
    padding-left: 5px;
    font-size: 12px;
    height: 32px;
    border: 1px solid #9dacb6;
    border-radius: 4px;
    color: #144870;
    margin-bottom: 8px;
  }

  .edit-name,
  .new-name {
    width: 300px;
  }

  .edit-number,
  .new-number {
    width: 125px;
  }
}

input:focus {
  outline: none;
}

.new-highenergy-section {
  padding: 10px 0px 5px 50px;

  .add-highenergy-error {
    color: red;
    font-size: 12px;
    margin: -66px 2px;
  }
}

.new-highenergy-button {
  width: 120px;
  border: none;
  border-radius: 3px;
  background-color: #0E75C4;
  color: white;
  font-size: 14px;
  display: inline-block;
  margin-right: 8px;
}

.new-highenergy-button-inactive {
  width: 120px;
  border: none;
  border-radius: 3px;
  background-color: #73c2f7;
  color: white;
  font-size: 14px;
  display: inline-block;
  margin-right: 8px;
}

.edit-highenergy {
  span {
    border-radius: 4px;
    margin-left: 20px;
    padding: 2px 12px;
    background: #d3ebfc;
    border: 1px solid #8cc3eb;
  }

  .hec-input-error {
    color: red;
    font-size: 15px;
    float: right;
    padding-right: 15px;
  }

  .edit-icon {
    width: 18px;
    height: 18px;
    margin-left: 12px;
    cursor: pointer;
  }
}

.hec-icon {
  width: 75px;
  height: 100px;
}

.hec-icon-avatar {
  // width: 100px;
  text-align: center;

  .pi-ellipsis-v {
    margin-top: 8px;
    float: right;
  }
}

.overlay-icon-container {
  display: flex;
  flex-direction: column;
  padding: 0.6rem;

  .menuitem {
    padding: 4px 5px;
    cursor: pointer;
  }
}

.icon-upload-section {
  input[type="file"] {
    display: none;
  }

  .upload-section {
    text-align: center;
  }

  .upload-section-button {
    text-align: center;

    .cancel-icon,
    .enable-edit {
      cursor: pointer;
    }

    span {
      padding: 5px 8px;
      ;
    }
  }
}