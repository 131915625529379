@import './variables';

// Common styles
@mixin center-element {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin center-element-vertical {
    display: flex;
    align-items: center;
}

@mixin center-element-horizontal {
    display: flex;
    justify-content: center;
}

@mixin scrollbar-style {
    &::-webkit-scrollbar {
      width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      background: none;
      margin: 10px 0 10px 0;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ebebeb;
      border-radius: 7px;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #c4cdd4;
    }
  }

// Breakpoints
@mixin mobile {
    @media (max-width: #{$mobileMaxWidth}) {
        @content;
    }
}